//fonts
;(function () {
	if (!('fonts' in document)) return;
	document.fonts.load('1em Cooper Std').then(function () {
		document.documentElement.className += 'fonts-loaded';
	});
})();


// console.log("this works");
// toggle button 
const toggle = document.querySelector(".toggle");
const mobileNav = document.querySelector(".mobile__nav");

function showNav() {
   this.classList.toggle("active");
   mobileNav.classList.toggle("show");
   console.log("are you here?");
}
toggle.addEventListener('click', showNav);

/**
 * Toppings List
 */
const toppings = document.querySelectorAll(".toppings");

function toppingsLimit() {
   const checkedToppings = document.querySelectorAll(".toppings:checked");
   if (checkedToppings !== null) {
      // this.setAttribute('disabled', 'disabled');
      if(checkedToppings.length > 2) {
         this.checked = false;
         console.log("You have picked two");
      }
   }
}

toppings.forEach(topping => {
   topping.addEventListener('click', toppingsLimit)
})

/**
 * Side Limit
 */
// const sides = document.querySelectorAll("input[name=side]");

// function sideLimit() {
//    const checkedSide = document.querySelector("input[name=side]:checked");
//    if (checkedSide) {
//       sides.forEach(side => {
//          side.setAttribute('disabled', 'disabled');
//       })
//    }
// }

/* 
function openingTimes() {
   // order window 
   const d = new Date();
   const weekday = d.getDay();
   const hours = d.getHours();
   const openingText = document.querySelector(".opening");
   const mobileText = document.querySelector(".mobile_opening");

   // closed Between 14.00 - 23:00 From Friday to Sunday 
   if (hours >= 14 && hours < 22 && weekday >= 6 && weekday == 0) {
      openingText.innerHTML = "Order Window is Closed";
      mobileText.innerHTML = "Order Window is Closed";
      //Between 9.00 - 22:00 Monday - thursday
   } else if (hours >= 9 && hours < 23 && weekday <= 5) {
      openingText.innerHTML = "Order Window is Open";
      mobileText.innerHTML = "Order Window is Open";
      //Between 01.00 - 15.00 Friday
   } else if (hours >= 1 && hours < 15 && weekday == 6) {
      openingText.innerHTML = "Order Window is Closed";
      mobileText.innerHTML = "Order Window is Closed";
   } else {
      openingText.innerHTML = "Order Window is Closed";
      mobileText.innerHTML = "Order Window is Closed";
   }
}
openingTimes(); */

// (function() {
//    console.log("I call myself");
// })();


